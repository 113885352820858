.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px + 2vmin);
  color: #1A0062;
  overflow-x: hidden;
  scrollbar-width: none;
  background-color: #fff6e6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
   -webkit-box-shadow: 0 0 0 50px #fff6e6 inset !important;
   -webkit-text-fill-color: #1A0062 !important; 
}

body {
  font-family: "Satisfy";
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "MateSC", cursive;
  src: url("https://fonts.googleapis.com/css2?family=Mate+SC&display=swap");
}

@font-face {
  font-family: "Banyu";
  src: url("../public/fonts/bBanyu.ttf");
}

::-webkit-scrollbar {
  display: none;
}
